import { RedoOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Col, DatePicker, Form, Input, Modal, Pagination, Row, Space, Table } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { getHistory_apiAdmin } from '../../services/history'

type Props = {}
const { RangePicker }: any = DatePicker;

const HistoryPage = (props: Props) => {
    const columns:any = [
        {
          dataIndex: 'timestamp',
          title: 'Timestamp',
          align: 'center',
          width: '15%',
          render: (_: any, record: any) => {
            return <>{moment(record.timestamp).format('YYYY-MM-DD')}</>;
          },
        },
       
        {
          dataIndex: 'edit_by',
          title: 'User',
          width: '15%',
        },
        {
          dataIndex: 'org_name',
          title: 'Organization',
          width: '15%',
        },
        {
          dataIndex: 'type',
          title: 'Type',
          width: '10%',
        },
        {
          dataIndex: 'detail',
          title: 'Detail',
          width: '20%',
        },
      ];
      const [dataHistory, setDataHistory] = useState([]);
      const [total, setTotal] = useState(0);
      const [pageSize, setPageSize] = useState(10);
      const [page, setPage] = useState(1);
      const [formSearch] = Form.useForm();
      const [formExport] = Form.useForm();
      const [isLoading, setIsLoading] = useState(false);
      const [dateSelect, setDateSelect] = useState<any>(null);
      const [createModalVisible, handleModalVisible] = useState<boolean>(false);
    
      const getData = async () => {
        setIsLoading(true);
        const res = await getHistory_apiAdmin({
          data: {
            page: 1,
            size: 10,
            order_by: [],
            query: {
              search_key: formSearch.getFieldValue('search'),
              date: formSearch.getFieldValue('date'),
            },
            exportData: false,
          },
        });

        console.log('res table history::', res);
        // {page: 1, size: 10, total: 182}
        setDataHistory(res.data);
    
        setTotal(res.meta.total);
        setPage(res.meta.page);
        setPageSize(res.meta.size);
        setIsLoading(false);
      };
    
      const searchHistory = async (value: any) => {
        setIsLoading(true);
        const new_date = value.date
          ? value.date.map((i: any) => {
            console.log(moment(i).format('YYYY-MM-DD'));
            return moment(i).format('YYYY-MM-DD');
          })
          : undefined;
  
        const res = await getHistory_apiAdmin({
          data: {
            page: 1,
            size: 10,
            order_by: [],
            query: { search_key: value.search, date: new_date },
            exportData: false,
          },
        });

        setDateSelect(new_date);
        setPage(res.meta.page);
        setPageSize(res.meta.size);
        setDataHistory(res.data);
        setTotal(res.meta.total);
        setIsLoading(false);
      };
    
      const resetTable = () => {
        setIsLoading(true);
        formSearch.resetFields();
        getData();
        setIsLoading(false);
      };
    
      useEffect(() => {
        getData();
      }, []);

  return (
    <div className="content-container">
    <Row>
        <Col>
          <h1 className="header-topic">{"History"}</h1>
        </Col>
    </Row>
    <Row style={{ background: 'white' }}>
      <Col xs={24}>
        <Row style={{ padding: '25px  50px 0px 30px' }} justify={'end'}>
          <Col xs={16}>
            <Form form={formSearch} onFinish={searchHistory}>
              <Row gutter={16} justify={'end'}>
                <Col>
                  <Form.Item name="search">
                    <Input placeholder="search" />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="date">
                    <RangePicker />
                  </Form.Item>
                </Col>
                <Col>
                  <Button htmlType="submit">
                    <SearchOutlined />
                  </Button>
                </Col>
                <Col>
                  <Button onClick={() => resetTable()}>
                    <RedoOutlined />
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Table
              loading={isLoading}
              columns={columns}
              dataSource={dataHistory}
              rowKey={(record: any) => record.id}
              pagination={false}
            />
          </Col>
        </Row>
        <Row style={{ padding: '12px 20px' }} justify="end" align="middle">
          <Pagination
            current={page}
            pageSize={pageSize}
            total={total == 0 ? 1 : total}
            showTotal={(totalpage, range) => {
              if (total == 0) {
                return `0 of ${total} items`;
              } else if (total < 10) {
                return `1-${totalpage} of ${total} items`;
              } else {
                return `${range[0]}-${range[1]} of ${total} items`;
              }
            }}
            onChange={async (pages, pageSizes) => {
              setIsLoading(true);
              setPage(pages);
              setPageSize(pageSizes);
              const res = await getHistory_apiAdmin({
                data: {
                  page: pages,
                  size: pageSizes,
                  order_by: [],
                  query: {
                    search_key: formSearch.getFieldValue('search'),
                    date: dateSelect == null ? undefined : dateSelect,
                  },
                  exportData: false,
                },
              });
              setDataHistory(res.data);
              setPage(res.meta.page);
              setPageSize(res.meta.size);
              setTotal(res.meta.total);
              setIsLoading(false);
            }}
            defaultPageSize={pageSize}
            defaultCurrent={page}
          />
        </Row>
      </Col>

    </Row>
  </div >
  )
}

export default HistoryPage
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4maps from "@amcharts/amcharts4/maps";
import * as am4core from "@amcharts/amcharts4/core";

export const chartConfig = {
    colorSetPalette: [
        am4core.color("#083A9D"),
        am4core.color("#3C79F5"),
        am4core.color("#6490F9"),
        am4core.color("#a4b6ff"),
        am4core.color("#30A2FF"),
        am4core.color("#3C79F5"),
        am4core.color("#6490F9"),
        am4core.color("#a4b6ff"),
    ],
    colorSetPalette2: [
        am4core.color("#8100fa"),
        am4core.color("#B15EFF"),
        am4core.color("#C88EFF"),
        am4core.color("#C4B0FF"),
        am4core.color("#E5D4FF"),
        am4core.color("#EDE4FF"),
    ],
    labelTemplateText: "{value.percent.formatNumber('#.#')}%",
    labelTextSize: 16,
    labelTextWeight: am4core.Label.BOLD,
    markerSize: 12,
    colorSet: [
        am4core.color("#B15EFF"),
        am4core.color("#E5D4FF")
    ],
    legendAlign: am4core.Sprite.CENTER,
    legendPosition: am4charts.Legend.RIGHT,
    legendWidth: 70,
}
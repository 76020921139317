import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Image as ImageTest, Spin } from 'antd'
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons'

const Canvas = (props) => {
  const canvasRef = useRef(null)
  const imgRef = useRef(null)
  const [data, setData] = useState('')
  const [data2, setData2] = useState('')

  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [isLoading, setIsloading] = useState(false)
  const [isFirstTime, setIsFirstTime] = useState(true)
  let myVideo = document.getElementById("video");


  useEffect(() => {

    getData()

  }, [props.dataScenario2])

  const getData = async () => {
    ///
    // if(isFirstTime==true){
    // props.setIsloading(true)
    const image = new Image();
    image.src = props.screenshot
    // console.log("image new:",image)
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    context.lineWidth = 6
    context.fillStyle = 'red'
    //image call onload
    image.onload = () => {
      var scale = Math.min(canvas.width / image.width, canvas.height / image.height);
      var x = (canvas.width / 2) - (image.width / 2) * scale;
      var y = (canvas.height / 2) - (image.height / 2) * scale;


      context.drawImage(image, x, y, image.width * scale, image.height * scale);

      // context.drawImage(image, 0, 0, canvas.width, canvas.height);

      props.annotation
        // .filter((item)=>item.score >= 0.4)
        .map((value) => {

          context.strokeStyle = "red"
          // if(x == 0){
          context.strokeRect(
            Number(value.bounding_box.x1 * image.width) * scale + x,
            Number(value.bounding_box.y1 * image.height) * scale + y,
            Number((value.bounding_box.x2 - value.bounding_box.x1) * image.width) * scale,
            Number((value.bounding_box.y2 - value.bounding_box.y1) * image.height) * scale,
          );
          // }

          // context.strokeRect(
          //   Number(value.bounding_box.x1  * canvas.width)  ,
          //   Number(value.bounding_box.y1 * canvas.height),
          //   Number((value.bounding_box.x2 - value.bounding_box.x1) * canvas.width) ,
          //   Number((value.bounding_box.y2 - value.bounding_box.y1) * canvas.height)
          // );
        })
      // console.log("url canvas:",canvas.toDataURL())
      setData(canvas.toDataURL())
      sleep(3000)
      props.setIsloading(false)
    };
  }

  const loadImage = (img) => {
    return new Promise((resolve, reject) => {
      // var tmp = new Image();

      img.onload = () => {
        img.src = props.screenshot;
        resolve();
      };
      img.onerror = reject;
      img.src = props.screenshot;
    });
  }

  const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

  // const testRender = useMemo(() => {
  //   const newData = data
  //   return newData
  // }, [data])

  const createPreview = async (visible, prevVisible, annotation) => {
    const image = new Image();
    image.src = props.screenshot

    const canvas = document.createElement("canvas");
    canvas.height = 720;
    canvas.width = 1280;
    // console.log("createPreview canvas :",canvas)

    const context = canvas.getContext('2d')


    image.onload = async () => {
      var scale = Math.min(canvas.width / image.width, canvas.height / image.height);
      var x = (canvas.width / 2) - (image.width / 2) * scale;
      var y = (canvas.height / 2) - (image.height / 2) * scale;

      context.drawImage(image, x, y, image.width * scale, image.height * scale);

      // context.drawImage(image, 0, 0, canvas.width, canvas.height);

      await annotation
        // .filter((item)=>item.score >= 0.4)
        .map((value) => {
          // ctx.strokeStyle = data_color[value.object]
          // console.log("value draw", value)
          context.lineWidth = 6
          // context.fillStyle = 'black'
          context.strokeStyle = "red"
          // if(x == 0){
          context.strokeRect(
            Number(value.bounding_box.x1 * image.width) * scale + x,
            Number(value.bounding_box.y1 * image.height) * scale + y,
            Number((value.bounding_box.x2 - value.bounding_box.x1) * image.width) * scale,
            Number((value.bounding_box.y2 - value.bounding_box.y1) * image.height) * scale,
          );
        })
      // console.log(canvas.toDataURL())
      setData2(canvas.toDataURL())
      setPreviewVisible(visible)
      // return canvas.toDataURL()
    };
  }


  // const testRender2 = useMemo(() => {
  //   const newData = data2
  //   return newData
  // }, [data2])

  return (
    <>
      {/* <Spin spinning={props.isLoading}> */}
      {data != '' &&
        <ImageTest
          style={{ width: "100%" }}
          src={data}
          preview={{
            // imageRender: (originalNode, info) => {
            //   const base64 = createPreview(originalNode.props.src, props.annotation)
            //   return <ImageTest src={data2} preview={false} />
            // },
            // forceRender: true,
            visible: isPreviewVisible,
            onVisibleChange: (visible, prevVisible) => createPreview(visible, prevVisible, props.annotation),
            src: data2,
            toolbarRender: () => <></>,
          }} 
          // preview={false}
          // onClick={()=>{
          //   props.setSeekTime(Number(props.begin_time / 1000));
          //   props.setEndTime(Number(props.ending_time / 1000));
          //   if (myVideo) {
          //     myVideo.currentTime = Number(props.begin_time / 1000);
          //   }
          // }}
          />}

      <canvas ref={canvasRef} width={426} height={240}
        style={{ display: "none" }}
      />
      {/* </Spin> */}
    </>
  )
}

export default Canvas
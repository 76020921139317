import {
  Layout,
  Row,
  Col,
  Dropdown,
  Avatar,
  Switch,
  Button,
  Form,
  Image,
  Popover,
  Badge,
  Modal,
  Progress,
  Input,
} from "antd";
import useNotification from "../components/Notification";
import {
  CloudUploadOutlined,
  InfoCircleOutlined,
  DownOutlined,
  QuestionCircleOutlined,
  UserOutlined,
  LogoutOutlined,
  BellOutlined,
  ExclamationCircleFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { useAuth } from "../utils/Auth/AuthWrapper";
import i18n from "i18next";
import { useRecoilState } from "recoil";
import { localeState, searchState, valueSearch } from "../utils/atoms";
import enUS from "antd/locale/en_US";
import thTH from "antd/locale/th_TH";
import { useEffect, useMemo, useRef, useState } from "react";
import Memo from "../assets/images/logoMemo.jpg";
import "./index.less";
import UploadVideo from "../components/UploadVideo";
import {
  showScenario,
  getData_Scenario,
  getData_ScenarioOption,
} from "../services/scenario";
import moment from "moment";
import Profile from "../components/Profile";
import { translate } from "../utils/libs/translate";
import { check_UserStatus, get_profile } from "../services/user";
import { getBreakpoint } from "../utils/libs/breakdown";
import { useLocation, useNavigate } from "react-router-dom";
import { useSocket } from "../utils/socketContext/socketProvider";
import NotiMessage from "../components/NotiMessage";
import { task_simpleSearch } from "../services/task";
interface UserData {
  user_name: string;
  org_name: string;
  user_email: string;
  profile_pic: string;
  role_name: string;
}

type Props = {
  collapsed: boolean;
};

const { confirm } = Modal;
const { Search } = Input;

const HeaderLayout = ({ collapsed }: Props) => {
  const { Header } = Layout;
  const auth = useAuth();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectTag, setSelectTag] = useState<any>([]);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [passwordFormVisible, setPasswordFormVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [userData, setUser] = useState<UserData>({
    user_name: "",
    org_name: "",
    user_email: "",
    profile_pic: "",
    role_name: "",
  });
  const [locale, setLocale] = useRecoilState(localeState);
  const [scenarioShow, setScenarioShow]: any = useState([]);
  const [uploadForm] = Form.useForm();
  const Breakpoint = getBreakpoint();
  const screen: any = Breakpoint();
  const navigate = useNavigate();
  const path = useLocation();
  const { openNotification, contextHolder } = useNotification();
  const socket = useSocket();

  Object.keys(screen).filter((item) => {
    if (screen[item] == false) {
      delete screen[item];
    }
  });

  useEffect(() => {
    getShowScenario();
    getProfile();
    checkStatusUser();
  }, []);

  const checkStatusUser = async () => {
    const res = await check_UserStatus();
    if (res.status == 0) {
      localStorage.clear();
      confirm({
        title: "Your user status has been modified. Please log in again.",
        icon: <ExclamationCircleFilled />,
        footer: (
          <Row justify="end" style={{ marginTop: "20px" }}>
            <Button
              type="primary"
              onClick={() => {
                navigate("/user/login");
                Modal.destroyAll();
              }}
            >
              Go To Login
            </Button>
          </Row>
        ),
      });
    }
  };

  const getProfile = async () => {
    try {
      const res = await get_profile();
      setUser({
        ...res.result[0],
        profile_pic: res.result[0].profile_pic + `?${new Date().getTime()}`,
      });
    } catch (error) {
      openNotification("error", "Cannot get user data", "");
    }
  };

  useEffect(() => {
    socketFunction();
  }, [socket.socket]);

  const socketFunction = async () => {
    try {
      socket.socket.on("user_statusChange", (data) => {
        if (auth.user?.user_id == data) {
          checkStatusUser();
        }
      });
      socket.socket.on("org_statusChange", (data) => {
        if (auth.user?.org_id == data) {
          checkStatusUser();
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  //Profile Form
  const [form] = Form.useForm();

  //profile function
  const handleProfile = () => {
    setIsProfileOpen(true);
  };

  const validateMessages = {
    required: "Please input your ${label}",
  };

  const onChangeLanguage = (checked: any) => {
    // if checked is false, French should be adopted
    if (!checked) {
      i18n.changeLanguage("thTH");
      setLocale(thTH);
    } else {
      i18n.changeLanguage("enUS");
      setLocale(enUS);
    }
  };

  //Profile handling
  const onClose = () => {
    setIsProfileOpen(false);
    setIsEdit(false);
    setPasswordFormVisible(false);
    form.resetFields();
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    uploadForm.resetFields();
    setProgressUpload(0);
    setSelectTag([]);
    setIsModalOpen(false);
  };

  const onChange = (checkedValues: any) => {
    // console.log("checked = ", checkedValues);
  };

  const getShowScenario = async () => {
    try {
      const titleScenario: any[] = [];
      const result = {
        page: 1,
        size: 10,
        order_by: [],
        query: {},
        exportData: false,
      };
      const scenarioTable = await getData_ScenarioOption();
      // const scenarioTable = await getData_Scenario(result);

      scenarioTable.data?.sort(
        (a: any, b: any) =>
          moment(a.created_timestamp).unix() -
          moment(b.created_timestamp).unix()
      );
      scenarioTable.data?.map((item: any) => {
        titleScenario.push({ label: item.title, value: item.scenario_id });
      });
      setScenarioShow(titleScenario);
    } catch (e) {
      console.error(e);
    }
  };
  const content = <>Notification</>;

  const items = [
    {
      key: "1",
      label: <p rel="noopener noreferrer">{translate("Profile")}</p>,
      icon: <UserOutlined onClick={handleProfile} />,
      onClick: () => {
        handleProfile();
      },
    },
    {
      key: "2",
      label: <p>{translate("Logout")}</p>,
      icon: <LogoutOutlined />,
      onClick: () => {
        socket.socket.emit("forceDisconnect");
        auth.logout();
      },
    },
  ];

  const items2 = [
    {
      key: "1",
      label: <p rel="noopener noreferrer">{translate("Profile")}</p>,
      icon: <UserOutlined />,
      onClick: () => {
        handleProfile();
      },
    },
    {
      key: "2",
      label: (
        <p
          onClick={() => {
            getShowScenario();
            showModal();
          }}
        >
          {translate("Upload")}
        </p>
      ),
      icon: <CloudUploadOutlined />,
      onClick: () => {
        getShowScenario();
        showModal();
      },
    },
    {
      key: "3",
      label: <p>{translate("Info")}</p>,
      icon: <InfoCircleOutlined />,
      onClick: () => {},
    },
    {
      key: "4",
      label: <p>{translate("Logout")}</p>,
      icon: <LogoutOutlined />,
      onClick: () => {
        socket.socket.emit("forceDisconnect");
        auth.logout();
      },
    },
  ];

  const menuItems = {
    items:
      Object.keys(screen)[Object.keys(screen).length - 1] === "sm" ||
      Object.keys(screen)[Object.keys(screen).length - 1] === "xs"
        ? auth.user.role === "SuperAdmin"
          ? items2.filter((item) => item.key !== "2")
          : items2
        : items,
  };

  const downloadFile = () => {
    //
    fetch(`${process.env.REACT_APP_FRONT_URL}memo.pdf`).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Manual.pdf";
        alink.click();
      });
    });
  };
  const [progressUpload, setProgressUpload] = useState<any>(0);
  const [processStatus, setProgressStatus] = useState<any>("active");
  const [hindUpload, setHindUpload] = useState<boolean>(false);
  const [searchSimple, setSearchSimple] = useRecoilState(searchState);
  const [valueSearchSimple, setValueSearchSimple] = useRecoilState(valueSearch);
  const [text, setText] = useState("");
  const inputSearch = useRef<any>(null);
  console.log(auth.user);
  const handleSearch = async (value: any) => {
    try {
      const data = {
        keyword: value,
      };
      const res = await task_simpleSearch(data);
      setText("");
      setSearchSimple(res.result);
      setValueSearchSimple(value);

      navigate("/search");
      // window.location.reload()
    } catch (e) {
      console.log("e", e);
    }
  };
  return (
    <Header className="header-layout" style={{ background: "white" }}>
      {contextHolder}
      <Row
        className="w-100 h-100"
        justify={"space-between"}
        align={"middle"}
        gutter={16}
        style={{ height: "100%" }}
        wrap={false}
      >
        <Col>
          <Image
            src={Memo}
            height={35}
            width={"auto"}
            preview={false}
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
          />
        </Col>
        <Col>
          <Row
            className="w-100 h-100"
            justify={"end"}
            align={"middle"}
            gutter={16}
            style={{ cursor: "pointer", height: "100%" }}
            wrap={false}
          >
            {auth.user.role != "SuperAdmin" && (
              <Col>
                <Row>
                  <Search
                    ref={inputSearch}
                    style={{ borderRadius: "25px", width: "350px" }}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    onSearch={handleSearch}
                  />
                </Row>
              </Col>
            )}
            {/* button change lang */}
            {/* <Col>
              <Switch
                checkedChildren="EN"
                unCheckedChildren="TH"
                defaultChecked
                onChange={onChangeLanguage}
              />
            </Col> */}
            <Col>
              <Row align={"middle"}>
                <UploadVideo
                  form={uploadForm}
                  isModalOpen={isModalOpen}
                  setSelectTag={setSelectTag}
                  selectTag={selectTag}
                  handleCancel={handleCancel}
                  validateMessages={validateMessages}
                  onChange={onChange}
                  scenarioShow={scenarioShow}
                  progressUpload={progressUpload}
                  setProgressUpload={setProgressUpload}
                  processStatus={processStatus}
                  setProgressStatus={setProgressStatus}
                  hindUpload={hindUpload}
                  setHindUpload={setHindUpload}
                  setIsModalOpen={setIsModalOpen}
                  openNotification={openNotification}
                />
                <Profile
                  form={form}
                  isProfileOpen={isProfileOpen}
                  onClose={onClose}
                  isEdit={isEdit}
                  setIsEdit={setIsEdit}
                  passwordFormVisible={passwordFormVisible}
                  setPasswordFormVisible={setPasswordFormVisible}
                  userData={userData}
                  setUser={setUser}
                  getProfile={getProfile}
                />
                {Object.keys(screen)[Object.keys(screen).length - 1] == "sm" ||
                Object.keys(screen)[Object.keys(screen).length - 1] == "xs" ? (
                  <></>
                ) : (
                  <>
                    {console.log("auth :", auth)}
                    {auth.user && auth.user.role !== "SuperAdmin" && (
                      <>
                        {hindUpload == true ? (
                          <Row align={"middle"}>
                            <Col
                              onClick={() => {
                                setIsModalOpen(true);
                                setHindUpload(false);
                              }}
                            >
                              <Progress
                                style={{ width: "120px" }}
                                percent={progressUpload}
                                size="small"
                                status={processStatus}
                              />
                            </Col>
                          </Row>
                        ) : (
                          <Button
                            type="primary"
                            style={{
                              color: "white",
                              background:
                                "linear-gradient(0.25turn,#CE5BEB, #1E88E5)",
                              borderRadius: "5px",
                              textDecoration: "none",
                              fontSize: "16px",
                              width: "150px",
                            }}
                            onClick={() => {
                              getShowScenario();
                              showModal();
                            }}
                          >
                            <CloudUploadOutlined /> {translate("Upload")}
                          </Button>
                        )}
                      </>
                    )}

                    <div
                      style={{ padding: "0px 5px 0px 16px", marginTop: "5px" }}
                    >
                      <QuestionCircleOutlined
                        className="info-icon"
                        style={{ fontSize: "20px" }}
                        onClick={downloadFile}
                      />
                    </div>
                    <NotiMessage />
                  </>
                )}
              </Row>
            </Col>
            <Col>
              <Row className="h-100 w-100" align={"middle"}>
                <Col>
                  <Dropdown
                    overlayClassName="header-menu-dropdown"
                    menu={menuItems}
                    placement="bottomRight"
                    trigger={["click"]}
                  >
                    <Row className="h-100 w-100" align={"middle"}>
                      <Avatar
                        style={{
                          boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.15)",
                        }}
                        // className="profile-avatar"
                        src={userData ? userData.profile_pic : null}
                        icon={<UserOutlined />}
                      />
                      <span
                        className="profile-avatar"
                        style={{ color: "black", marginLeft: "10px" }}
                      >
                        {/* Sittichai Saetiaw */}
                        {userData ? userData.user_name : null} &nbsp;
                        <DownOutlined style={{ fontSize: "12px" }} />
                      </span>
                    </Row>
                  </Dropdown>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Header>
  );
};

export default HeaderLayout;

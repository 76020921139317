import { Col, Form, Input, Modal, Radio, Row } from "antd";
import React from "react";
import { task_addEvaluate } from "../../../../services/task";

type Props = {
  evaluateOpen: any;
  setEvaluateOpen: any;
  form?: any;
  task_id: any;
};

const EvaluateVideo = (props: Props) => {
  const onFinish = async (value: any) => {
    try {
      const data = {
        task_id: props.task_id,
        ...value,
      };
      const res = await task_addEvaluate(data);
      if (res.success == true) {
        props.setEvaluateOpen(false);
      }
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Modal
      title="Overall Evaluation"
      open={props.evaluateOpen}
      onCancel={() => props.setEvaluateOpen(false)}
      closable={false}
      width={800}
      onOk={() => props.form.submit()}
    >
      <Form layout="vertical" form={props.form} onFinish={onFinish}>
        <Row style={{ padding: "10px 10px" }}>
          <Col xs={24}>
            <Form.Item
              name="rate"
              rules={[
                {
                  required: true,
                  message: "Please input your Rating!",
                },
              ]}
            >
              <Radio.Group>
                <Radio value={"1"}>poor</Radio>
                <Radio value={"2"}>fair</Radio>
                <Radio value={"3"}>average</Radio>
                <Radio value={"4"}>good</Radio>
                <Radio value={"5"}>excellent</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item label="Comment" name="comment">
              <Input.TextArea rows={5} style={{ resize: "none" }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EvaluateVideo;

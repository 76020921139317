import { Image } from 'antd'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

const DrawAD_len = (props) => {
  const params = useParams()
  const canvasRef = useRef(null)
  const [data, setData] = useState()
  const videos = document.getElementById("video")
  console.log("props.", props.settime)
  useEffect(() => {
    getData()
  }, [props.settime])

  const getData = () => {
    const video = document.getElementById(`video${props.ind}`)
    video.currentTime = Number(props.settime / 1000)
    console.log("video", video)
  }

  const newData = useMemo(() => {
    const res = data
    return res
  }, [data])
  return (
    // <div id="popUpCanvas">

    // </div>
    <>
      <video
        id={`video${props.ind}`}
        preload="metadata"
        width="100%"
        height="100%"
      >
        <source
          src={`${process.env.REACT_APP_VIDEO_PATH}${params.id}`}
          // src={props.screenshot}
          type="video/mp4"
          onLoad={() => console.log("tetetete")}
        />
      </video>
    </>
  )
}

export default DrawAD_len
import config from "../config/config";
import { getToken } from "../utils/prefix"

  export async function subtitle_APIDownload(data) {
    const res = await fetch(process.env.REACT_APP_BACKEND+ `/tasksub/download?task_id=${data.task_id}&extension=vtt`, {
    //   body: JSON.stringify(data),
      method: "GET",
      headers: {
      "x-access-token": getToken(),
        // "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.blob();
    return dataRespone;
  }
  

  export async function subtitle_APIGetSub(data) {
    const res = await fetch(process.env.REACT_APP_BACKEND+ `/tasksub/search-video-id`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }
import config from "../config/config";
import { getToken } from "../utils/prefix";

// export async function getSetting(options?: { [key: string]: any }) {
//     return request<Record<string, any>>('/api/manage/settings/getSettings', {
//       method: 'POST',
//       headers: {
//         'x-access-token':
//           'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiODA1MTM2Iiwicm9sZSI6MSwiaWF0IjoxNjg3NDQ1ODUxLCJleHAiOjE3MDQwMzQ2NTF9.gXMcRV-biF8gkSRjQKgUzLsriloT9tT75Cy831HCJ_E',
//       },
//       ...(options || {}),
//     });
//   }


  export async function getSetting() {
    const res = await fetch(process.env.REACT_APP_BACKEND+ `/manage-setting/getsetting`, {
    //   body: JSON.stringify(data),
      method: "POST",
      headers: {
        'x-access-token':
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiODA1MTM2Iiwicm9sZSI6MSwiaWF0IjoxNjg3NDQ1ODUxLCJleHAiOjE3MDQwMzQ2NTF9.gXMcRV-biF8gkSRjQKgUzLsriloT9tT75Cy831HCJ_E',
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }

  export async function setSetting(data) {
    const res = await fetch(process.env.REACT_APP_BACKEND+ `/manage-setting/setSettings`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        'x-access-token':
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiODA1MTM2Iiwicm9sZSI6MSwiaWF0IjoxNjg3NDQ1ODUxLCJleHAiOjE3MDQwMzQ2NTF9.gXMcRV-biF8gkSRjQKgUzLsriloT9tT75Cy831HCJ_E',
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }
  
  export async function manualBackup() {
    const res = await fetch(process.env.REACT_APP_BACKEND+ `/manage-setting/execBackup`, {
      // body: JSON.stringify(data),
      method: "POST",
      headers: {
        'x-access-token':
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiODA1MTM2Iiwicm9sZSI6MSwiaWF0IjoxNjg3NDQ1ODUxLCJleHAiOjE3MDQwMzQ2NTF9.gXMcRV-biF8gkSRjQKgUzLsriloT9tT75Cy831HCJ_E',
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }
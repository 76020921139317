import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "antd";
import { useParams } from "react-router-dom";
// import { scrollToView } from "../../../utils/libs/scroll-to-view";
import scrollIntoView from 'scroll-into-view';
import EvaluateVideo from "./component/Evaluate";
import { task_getEvaluate } from "../../../services/task";

// type Props = {
//   subtitle: any;
//   seekTime: any;
//   endTime: any;
//   allSub:any;
//   indexSelect:any;
// };

const VideoDisplay = (props) => {
  const params = useParams();
  let myVideo = document.getElementById("video");
  const [indexSub, setIndexSub] = useState(0);
  const [iIndex, setI] = useState();
  // const [focusRow, setFocusRow] = useState('');

  const timeUpdate = () => {
    try {
      if (myVideo && props.endTime != null) {
        if (myVideo.currentTime >= props.endTime) {
          myVideo.currentTime = props.seekTime;
          // myVideo.pause();
        }
      }
      //
      if(myVideo !== null) {

        const st = props.allSub.map((item:any) => 
          // Number(parseInt(item.start_time.split(':')[0])*3600) + Number(parseInt(item.start_time.split(':')[1])*60) + Number(parseFloat(item.start_time.split(':')[2]).toFixed(3)) 
          Number(item.start_time / 1000)
      )
  
        const st2 = st.map((item:any) => item < 1.000 ? item = 0.000 : item = item)
      
        const et = props.allSub.map((item:any) => 
          // Number(parseInt(item.end_time.split(':')[0])*3600) + Number(parseInt(item.end_time.split(':')[1])*60) + Number(parseFloat(item.end_time.split(':')[2]).toFixed(3)) )
          Number(item.end_time / 1000))

  
        for(let i = indexSub; i < st2.length; i++){
          // console.log("myVideo.allsub ",props.allSub )
          // console.log("myVideo.currentTime ",myVideo.currentTime  )
          // console.log("myVideo.st2[i] ",st2 )
          // console.log("myVideo.et[i] ",et )
          // console.log("props.allSub[indexSub].sub_id::",props.allSub)

          if(myVideo.currentTime >= st2[i] && myVideo.currentTime < et[i]){
            if(st2[i] == st2[i+1] || st2[i] == st2[i-1]){
              console.log("props.subtitle 1",1)

              // getAbsoluteOffsetFromBody(props.allSub[indexSub].sub_id);
              // scrollIntoView(document.querySelector(`.da93927d-adfe-4b56-a666-597960d8926e`), {
              //   align: {
              //     top: 0,
              //   },
              // });
              scrollIntoView(document.querySelector(`.scroll-${props.allSub[indexSub].sub_id}`), {
                align: {
                  top: 0,
                },
              });
              // getAbsoluteOffsetFromBody(document.getElementById("sTime2"+props.allSub[indexSub].sub_id));
              props.setFocusRow(`.scroll-${props.allSub[indexSub].sub_id}`)
              setI(indexSub);
            }else{
              console.log("props.subtitle 2",2)
              if(props.focusRow == `.scroll-${props.allSub[i].sub_id}`){

              }else{
                scrollIntoView(document.querySelector(`.scroll-${props.allSub[i].sub_id}`), {
                  align: {
                    top: 0,
                  },
                });
              }
              props.setFocusRow(`.scroll-${props.allSub[i].sub_id}`)
              setI(i)
            }    
          }  
        }
      }
  
    } catch (e) {
      console.error(e);
    }
  };

  const getAbsoluteOffsetFromBody = (el:any) =>
    {   
        // var _x = 0;
        // var _y = 0;
        // while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) )
        // {
        //     _y += el.offsetTop - el.scrollTop + el.clientTop;
        //     el = el.offsetParent;
  
        // }
        // if(window.innerWidth > 992){
        //   // document.getElementById("scrollableDiv")?.scrollTo(_x ,_y -250);
        //   scrollToView(el)
        // }
        // if(window.innerWidth >= 640 && window.innerWidth <= 992 ){
        //   // document.getElementById("scrollableDiv")?.scrollTo(_x ,_y -640);
        //   scrollToView(el)
        // }      
        // if(window.innerWidth < 640){
        //   // document.getElementById("scrollableDiv")?.scrollTo(_x ,_y -580);
        //   scrollToView(el)
        // }
        // scrollToView(el)
    }
  
const [evaluateOpen,setEvaluateOpen] = useState(false)
const [formEvaluate] = Form.useForm()

const getData = async() => {
  try{
    const res = await task_getEvaluate({task_id:params.id})
    if(res.success == true){
      formEvaluate.setFieldsValue({rate:res.data[0].rating,comment:res.data[0].comment})
    }
  }catch(e){
    console.error(e)
  }
}

useEffect(()=>{
  getData()
},[])
  return (
    <Col md={24} lg={12}>
      {props.header()}
      <EvaluateVideo evaluateOpen={evaluateOpen} setEvaluateOpen={setEvaluateOpen} form={formEvaluate} task_id={params.id}/>
      <Row className="tests1">
        <Col xs={24} style={{ maxHeight: "580px" }}>
          <video
            id="video"
            preload="metadata"
            width="100%"
            height="100%"
            controls
            onTimeUpdate={timeUpdate}
          >
            <source
              src={`${process.env.REACT_APP_VIDEO_PATH}${params.id}`}
              // src={`/screenshots/lakorn_ch7.mp4`}
              type="video/mp4"
            />
            <track
              label="English"
              kind="subtitles"
              src={props.subtitle}
              default
            />
          </video>
        </Col>
      </Row>
      <Row style={{marginTop:"1rem"}} justify={"end"}>
        <Button type={"primary"} onClick={()=>setEvaluateOpen(true)}>Evaluate</Button>
      </Row>
    </Col>
  );
};

export default VideoDisplay;

import { CaretRightOutlined, DownOutlined, SwapOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Empty, Image, MenuProps, Row, Space, Spin, Tag } from "antd";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "../../styles/index.less";
import Canvas from "./Canvas";
import { task_getCountModels, task_getDataModels } from "../../../../services/task";
import { useParams } from "react-router-dom";
const { CheckableTag } = Tag;
// type Props = {
//   dataModel: any;
//   dataScenario: any;
//   reloadObject: boolean;
//   selectModelObj: any;
//   setSelectModelObj: any;
// };

const Object = ({
  dataModel,
  dataScenario,
  reloadObject,
  selectModelObj,
  setSelectModelObj,
  modelTag,
  setModelTag,
  radioModel,
  setSeekTime,
  setEndTime
}) => {
  const [dataScenario2, setDataScenario2] = useState([]);
  const params = useParams();
  const [mockClick, setMockClick] = useState(true);
  const model_color = JSON.parse(process.env.REACT_APP_MODEL_COLOR)
  const [isLoading, setIsloading] = useState(false)
  let [countTagModels, setCountTagModels] = useState({})
  let myVideo = document.getElementById("video");

  const getData = async (keyword) => {
    try {
      setIsloading(true)
      console.log("keword", keyword)
      const res = await task_getDataModels({ task_id: params.id, model: keyword })
      console.log("getDataModel", res)
      if (keyword == "ad_area") {
        const dataCheckRatio = res[0]?.data.filter((item) => {
          if (item.ratio > 0.125) {
            return item
          }
        })
        setDataScenario2(dataCheckRatio)
      } else {
        setDataScenario2(res.length == 0 ? res : res[0].data)
      }
      setIsloading(false)
    } catch (e) {
      console.error("err:", e)
    }
  };

  //////
  const dataModelObject = dataModel.filter(
    (i) => i.model_type == "b875130b-0492-11ee-a8f8-0242ac1b0005"
  );

  // console.log("dataScenario", dataScenario[0]);
  let alldata = [];
  dataScenario[0]?.object_task.map((i) => {
    i.annotation.map((d) => {
      alldata.push(d);
    });
  });
  // console.log("alldata", alldata);
  const uniqValue = (data, key) => {

    return [
      ...new Map(
        data.map(x => [key(x), x])
      ).values()
    ]
  }
  // console.log("uniqValue",uniqValue(alldata, item => item.object))
  const objTag = uniqValue(alldata, item => item.object)

  const tagObject = alldata.reduce((group, product) => {
    const { object } = product;
    // console.log("object in reduce",object)
    // console.log("product",product)
    group[object] = group[object] ?? [];
    group[object].push(product);
    return group;
  }, {});

  const resultTag = dataModelObject.map((d) => {
    if (tagObject[d.model_name] != undefined) {
      return { name: d.text, value: tagObject[d.model_name].length }
    } else {
      return null
    }
  }).filter((item) => item != null)



  useEffect(() => {
    console.log("in modal canvas");
    setIsloading(true)
    // getData();
    // setSelectModelObj(objTag.map((item)=>item.object))
    getCountModelTags()
    // setDataScenario2(dataScenario[0].object_task)
  }, [radioModel]);

  const [selectedTags, setSelectedTags] = useState([])

  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    console.log('You are interested in: ', nextSelectedTags);
    setSelectedTags(nextSelectedTags);
  };



  // const modifineData = useMemo(() => {
  //   let datass = dataScenario2
  //   console.log("dataScenario2 useMemo", dataScenario2)
  //   return datass
  // }, [dataScenario2])

  const getCountModelTags = async () => {
    try {
      let selectTag = selectModelObj
      const res = await task_getCountModels({ task_id: params.id, type: "object_task" })

      console.log("getCountModelTags::", res)
      // setCountTagModels(res.counts)

      const obj_area = await task_getDataModels({ task_id: params.id, model: "ad_area" })
      const dataCheckRatio = obj_area[0]?.data.filter((item) => {
        if (item.ratio > 0.125) {
          return item
        }
      }).length
      const newCount = { ...res.counts, "ad_area": res.counts["ad_area"] == undefined ? 0 : dataCheckRatio }
      // console.log("dataCheckRatio",countTagModels["ad_area"])
      setCountTagModels(newCount)
      console.log("name data model", newCount)
      console.log("name data model", dataModelObject[0])
      dataModelObject.map((d) => {

        if (newCount[d.model_name] == undefined) {
          // selectTag = d.model_name
          return 0
        } else if (newCount[d.model_name] == 0 && selectTag.length == 0) {
          // selectTag = d.model_name
          // setSelectModelObj(dataModelObject[0].model_name)
        } else if (newCount[d.model_name] > 0 && selectTag.length == 0) {
          selectTag = d.model_name
          setSelectModelObj(d.model_name)
        } else {
          return 0
        }
      })
      getDataModel(selectTag)
    } catch (e) {
      console.log(e)
    }
  }

  const getDataModel = async (selectTag) => {
    const res = await task_getDataModels({ task_id: params.id, model: selectModelObj == '' ? selectTag : selectModelObj })

    // console.log("getDataModel", res[0].data)
    if (selectTag == "ad_area") {
      const dataCheckRatio = res[0]?.data.filter((item) => {
        if (item.ratio > 0.125) {
          return item
        }
      })
      setDataScenario2(dataCheckRatio)
    } else {
      setDataScenario2(res.length == 0 ? res : res[0].data)
    }
    // setDataScenario2(res.length == 0 ?res:res[0].data)
    setIsloading(false)
  }

  return (
    <Row style={{ width: "100%" }}>
      <Col xs={24} style={{ width: "100%" }}>
        <Space size={[0, 8]} wrap>
          {
            dataModelObject.map(d => {
              return (
                <Tag
                  key={d.model_name}
                  color={selectModelObj != d.model_name ? "default" : "#55acee"}
                  style={{ cursor: "pointer", borderWidth: "4px" }}
                  bordered={false}
                  className={selectModelObj.length == 0 ? "video-tag-selected" : selectModelObj != d.model_name ? "video-tag-unselected" : "video-tag-selected"}
                  onClick={() => {
                    getData(d.model_name)
                    setSelectModelObj(d.model_name)
                    // setModelTag(d.model_name)
                  }}
                >
                  {d.text}{" "}
                  {"("}
                  {countTagModels && countTagModels[d.model_name] == undefined
                    ? 0
                    : countTagModels[d.model_name]}
                  {")"}
                </Tag>
              );
            })
          }
        </Space>
      </Col>
      <Col
        xs={24}
        style={{
          background: "#E2E2E8",
          height: "370px",
          borderRadius: "4px",
          marginTop: "0.5rem",
          overflowX: "hidden",
          overflowY: "scroll",
        }}
      >
        <Spin spinning={isLoading} size="large">
          <Row gutter={[16, 16]}>

            {dataScenario2?.map((d, ind) => {
              // {modifineData?.map((d, ind) => {
              // console.log("ddd image", d);
              return (
                <Col
                  key={ind}
                  flex="0 1 33%"
                  style={{
                    width: "100%",
                  }}

                >
                  <Row>
                    {/* <Image
                    src={`${process.env.REACT_APP_IMG_PATH}/memo/results/${params.id}/image_shot/${d.screenshot}`}
                    // src={process.env.REACT_APP_IMG_PATH+ d.screenshot}
                    width={"100%"}
                    height={"auto"}
                    preview={true}
                  /> */}
                    <div style={{ width: "100%", height: "100%", opacity: 1, display: isLoading == true ? "none" : "block" }}>
                      <Canvas
                        // screenshot={`/screenshots/${d.screenshot}`} 
                        screenshot={`${process.env.REACT_APP_IMG_PATH}memo/results/${params.id}/image_shot/${d.screenshot}`}
                        ind={ind} annotation={d.annotation} threshold={d.threshold} isloading={isLoading} setIsloading={setIsloading}
                        dataScenario2={dataScenario2}
                        begin_time={d.begin_time}
                        ending_time={d.ending_time}
                        setSeekTime={setSeekTime}
                        setEndTime={setEndTime}
                      />
                    </div>
                  </Row>
                  <Row justify="space-between">
                    <Col
                      style={{ cursor: "default", lineHeight: "1.8" }}
                    >{(d.annotation[0].score * 100).toFixed(2)} %</Col>
                    <Col
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSeekTime(Number(d.begin_time / 1000));
                        setEndTime(Number(d.ending_time / 1000));
                        if (myVideo) {
                          myVideo.currentTime = Number(d.begin_time / 1000);
                        }
                      }}
                    >
                      <CaretRightOutlined style={{ fontSize: "16pt" }} />
                    </Col>
                  </Row>
                </Col>
              );
            })}
            {dataScenario2?.length == 0 && (
              <Col xs={24} style={{ minWidth: "100%" }}>
                <Empty />
              </Col>
            )}
          </Row>
        </Spin>
      </Col>
    </Row>
  );
};

export default Object;

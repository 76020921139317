import { Col, Input, Row, Button, List } from 'antd'
import React from 'react'

type Props = {}

const KeywordList = (props: Props) => {
  return (
    <div className="content-container">
        <Row justify={'center'}>
            <Col span={18}>
                <Row>
                    <h1>Name: Test</h1>
                </Row>
                <Row justify={'center'} gutter={16}>
                    <Col span={18}>
                        <Input/>
                    </Col>
                    <Col>
                        <Button>
                            Add
                        </Button>
                    </Col>
                </Row>
                <Row  justify={'center'} style={{marginTop:"2rem"}}>
                    <Col span={20} style={{background:"white",height:"500px"}}>
                        <List
                            itemLayout="horizontal"
                            dataSource={[{},{}]}
                            renderItem={(item, index) => (
                            <List.Item style={{padding:"15px 40px"}}>
                                <List.Item.Meta
                                    title={"keyword"}
                                />
                                <Row gutter={16}>
                                    <Col>edit</Col>
                                    <Col>delete</Col>
                                </Row>
                            </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>
  )
}

export default KeywordList
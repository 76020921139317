import config from "../config/config";
import { getToken } from "../utils/prefix"

  export async function getData_Scenario(data) {
    const res = await fetch(process.env.REACT_APP_BACKEND+ `/scenario/search`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }
  
  export async function deleteScenario(data) {
    const res = await fetch(process.env.REACT_APP_BACKEND+ `/scenario/delete`, {
      body: JSON.stringify(data),
      method: "DELETE",
      headers: {
      "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }

  export async function createScenario(data) {
    const res = await fetch(process.env.REACT_APP_BACKEND+ `/scenario/create`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }
  
  export async function get_ScenarioByID(data) {
    const res = await fetch(process.env.REACT_APP_BACKEND+ `/scenario/getDataById`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }
  
  export async function put_ScenarioAPI(data) {
    const res = await fetch(process.env.REACT_APP_BACKEND+ `/scenario/update`, {
      body: JSON.stringify(data),
      method: "PUT",
      headers: {
      "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }

  export async function showScenario(data) {
    const res = await fetch(process.env.REACT_APP_BACKEND+ `/scenario/search`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }

  export async function getData_ScenarioOption(data) {
    const res = await fetch(process.env.REACT_APP_BACKEND+ `/scenario/options`, {
      // body: JSON.stringify(data),
      method: "GET",
      headers: {
      "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }
import { Layout } from "antd";

type Props = {
  collapsed: boolean;
};

const FooterLayout = ({ collapsed }: Props) => {
  const { Footer } = Layout;
  return (
    <Footer
      style={
        collapsed === true ? { marginLeft: "80px" } : { marginLeft: "200px" }
      }
    >
      footer
    </Footer>
  );
};

export default FooterLayout;

import {
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Row,
  Button,
  Image,
  List,
  Tag,
} from "antd";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { task_advancedSearch } from "../../services/task";
import { useNavigate } from "react-router-dom";
import { searchState, valueSearch } from "../../utils/atoms";
import { useRecoilState } from "recoil";
import { keywordAPI_checkWordDuplicate } from "../../services/keyword";

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

type PaginationPosition = "top" | "bottom" | "both";
type PaginationAlign = "start" | "center" | "end";

const SearchPage = () => {
  const [form] = Form.useForm();
  const [dataSearch, setDataSearch] = useState<any>([]);
  const [position, setPosition] = useState<PaginationPosition>("bottom");
  const [align, setAlign] = useState<PaginationAlign>("center");
  const navigate = useNavigate();
  const [searchSimple, setSearchSimple] = useRecoilState(searchState);
  const [valueSearchSimple, setValueSearchSimple] = useRecoilState(valueSearch);
  const [resultSearchSimple, setResultSearchSimple] = useState(true);

  console.log("searchSimple", searchSimple);
  console.log("valueSearchSimple", valueSearchSimple);
  const onChange = () => {};

  const handleSearch = async (value: any) => {
    try {
      console.log("serach value", value);
      setSearchSimple([]);
      setValueSearchSimple("");
      setResultSearchSimple(false);
      const data = {
        search_title: value.title ? value.title : null,
        search_tag: value.tag ? value.tag : null,
        search_subtitle: value.subtitle ? value.subtitle : null,
        search_created_start_date:
          value.date != null
            ? moment(value.date[0].$d).format("YYYY-MM-DD 00:00:00")
            : null,
        search_created_end_date:
          value.date != null
            ? moment(value.date[1].$d).format("YYYY-MM-DD 00:00:00")
            : null,
      };
      const res = await task_advancedSearch(data);
      console.log("res result::", res);
      setDataSearch(res.result);
    } catch (e) {
      console.error(e);
    }
  };

  const highlightMatchingWords = (text: any, highlights: any) => {
    // console.log('123', highlights, typeof highlights, text, typeof text);

    if (typeof text !== "string") {
      return null; // or handle the case when text is not a string
    }

    const highlight = highlights
      ?.split(/(\s+)/)
      .filter((e: any) => e.trim().length > 0);
    const regex = new RegExp(`(${highlight?.join("|")})`, "gi");
    // console.log('456', regex);

    const parts = text.split(regex);
    // console.log('789', parts);

    return (
      <>
        {parts.map((part, i) =>
          highlight?.includes(part.toLowerCase()) ? (
            <mark style={{ background: "#bfe3ee" }} key={i}>
              {part}
            </mark>
          ) : (
            part
          )
        )}
      </>
    );
  };

  const dataSearchList = useMemo(() => {
    // if (searchSimple) {
    //   const result = searchSimple;
    //   // setResultSearchSimple(true)
    //   return result;
    // } else {
    //   const result = dataSearch;
    //   // setResultSearchSimple(true)
    //   return result;
    // }
    if (searchSimple.length != 0) {
      const result = searchSimple;
      // setResultSearchSimple(true)
      return result;
    } else {
      const result = dataSearch;
      // setResultSearchSimple(true)
      return result;
    }
  }, [searchSimple, dataSearch]);

  return (
    <div className="content-container">
      <Row>
        <Col sm={24}>
          <Collapse defaultActiveKey={["1"]} onChange={onChange}>
            <Panel header="Advanced Search" key="1">
              <Form layout="vertical" form={form} onFinish={handleSearch}>
                <Row gutter={16} style={{ padding: "20px 60px" }}>
                  <Col sm={24}>
                    <Form.Item label="Title" name="title">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col sm={12}>
                    <Form.Item label="Tag" name="tag">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col sm={12}>
                    <Form.Item label="Created Date" name="date">
                      <RangePicker style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col sm={24}>
                    <Form.Item label="Subtitle" name="subtitle">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <Row gutter={16} justify={"end"}>
                <Col>
                  <Button type="primary" onClick={() => form.submit()}>
                    Search
                  </Button>
                </Col>
                <Col>
                  <Button onClick={() => form.resetFields()}>Claer</Button>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Col>
      </Row>
      {valueSearchSimple ? (
        <Row style={{ padding: "20px 0s" }} gutter={16} align={"bottom"}>
          <Col style={{ fontSize: "16pt" }}>คำค้นหา</Col>
          <Col style={{ fontSize: "14pt", fontWeight: "200" }}>
            "{valueSearchSimple}"
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <Row style={{ marginTop: "1rem" }}>
        <List
          style={{ width: "100%" }}
          pagination={{ position, align }}
          dataSource={dataSearchList.filter(
            (item: any) => item.deleted_timestamp == null
          )}
          // dataSource={dataSearch}
          renderItem={(item: any, index) => (
            <List.Item
              style={{ cursor: "pointer", height: "200px", maxHeight: "200px" }}
              onClick={() => navigate(`/video/${item.task_id}`)}
            >
              {/* <Col key={index} sm={24} style={{
                        // border:"1px solid black",
                        height:"240px",
                        borderRadius:"10px"}}>
                        <Row gutter={8} align={'middle'} justify={'space-evenly'} style={{height:"100%"}}>
                            <Col sm={8} style={{height:"100%",padding:"15px 20px"}}>
                                <Image src= {"/screenshots/2.jpg"} height={"100%"} style={{borderRadius:"15px"}}/>
                            </Col>
                            <Col sm={16} style={{background:"pink",height:"100%",padding:"25px 20px"}}>
                                <Row>
                                    <p style={{fontSize:"16pt"}}>{index+1}Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...</p>
                                </Row>
                                <Row>
                                    <p style={{fontSize:"12pt"}}>tag</p>
                                </Row>
                                <Row>
                                    <p style={{fontSize:"12pt"}}>{moment("2024-04-19 15:07:00").format('DD MMMM YYYY')}</p>
                                </Row>
                                <Row>
                                    <p style={{fontSize:"12pt"}}>subtitle search</p>
                                </Row>
                            </Col>
                        </Row>
                    </Col> */}
              <List.Item.Meta
                avatar={
                  <Image
                    preview={false}
                    className="card-img"
                    alt="video-profile"
                    height={160}
                    width={280}
                    src={process.env.REACT_APP_IMG_PATH + item.image_path}
                  />
                }
                title={<h2>{item.title}</h2>}
                description={
                  <>
                    <Row style={{ marginBottom: "10px" }}>
                      <span
                        style={{
                          fontWeight: "300",
                          color: "black",
                          marginRight: "10px",
                        }}
                      >
                        Tag:
                      </span>
                      {item?.tag?.split(",").map((item: any, i: number) => {
                        if (item.length == 0) {
                          return <>ไม่มี</>;
                        } else {
                          return (
                            <Tag
                              key={i}
                              // color={tag.color}
                            >
                              {item}
                            </Tag>
                          );
                        }
                      })}
                    </Row>
                    <Row>
                      <p>
                        {moment(item.created_timestamp).format("DD MMMM YYYY")}
                      </p>
                    </Row>
                    {item.text && (
                      <Row className="box-textsubtitle">
                        {item.text.map((item: any, index: number) => {
                          if (index == 0 || index == 1) {
                            return (
                              <p key={index}>
                                {highlightMatchingWords(
                                  item,
                                  resultSearchSimple == true
                                    ? valueSearchSimple
                                    : form.getFieldValue("subtitle")
                                )}{" "}
                                ,
                              </p>
                            );
                          }
                        })}
                      </Row>
                    )}
                  </>
                }
              />
            </List.Item>
          )}
        />
      </Row>
    </div>
  );
};

export default SearchPage;

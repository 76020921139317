import config from "../config/config";
import { getToken } from "../utils/prefix"

  export async function api_getAllVideo(data) {
    const res = await fetch(process.env.REACT_APP_BACKEND+ `/task/taskvideo`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }

  export async function api_getVideoID(data) {
    const res = await fetch(process.env.REACT_APP_BACKEND+ `/task/gettaskid`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }

  export async function canvasApi(data) {
    const res = await fetch(process.env.REACT_APP_BACKEND+ `/task/canvas`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }
  
  export async function mongo_create(data) {
    const res = await fetch(process.env.REACT_APP_BACKEND+`/result/`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }
  
  export async function mongo_getData(data) {
    const res = await fetch(process.env.REACT_APP_BACKEND+ `/result/getDataById/${data}`, {
      // body: JSON.stringify(data),
      method: "GET",
      headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }
import "./../styles/index.less";
import { Breadcrumb, Row, Spin } from "antd";
import { LeftCircleFilled } from "@ant-design/icons";
import VideoDisplay from "./VideoDisplay";
import DetailDisplay from "./DetailDisplay";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { api_getVideoID, mongo_getData } from "../../../services/video";
import { get_ScenarioByID } from "../../../services/scenario";
import {
  subtitle_APIDownload,
  subtitle_APIGetSub,
} from "../../../services/subtitle";
import useNotification from "../../../components/Notification";
import {
  task_createDashboard,
  task_drawCanvas,
  task_getCountModels,
  task_getDashboardID,
  task_getDataModels,
} from "../../../services/task";
import { array } from "@amcharts/amcharts4/core";
import { selectedAllModel } from "../../../services/model";

type Props = {};

const VideoDetail = (props: Props) => {
  const params = useParams();
  const navigate = useNavigate();
  //
  const [titleVideo, setTileVideo] = useState("");
  const [dataTask, setDataTask] = useState([]);
  const [dataModel, setDataModel] = useState([]);
  const [subtile, setSubtitle] = useState("");
  const [radioScenario, setRadioScenario] = useState(1);
  const [radioModel, setRadioModel] = useState<any>(null);
  const [dataScenario, setDataScenario] = useState<any>([]);
  const [seekTime, setSeekTime] = useState<any>(null);
  const [endTime, setEndTime] = useState<any>(null);
  const { openNotification, contextHolder } = useNotification();
  const [focusRow, setFocusRow] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    try {
      setIsLoading(true);
      const data = {
        task_id: params.id,
      };
      const res = await api_getVideoID(data);

      if (res.status == 200) {
        console.log("res getData:", res.data.video.status);
        //checkstatus case เข้าผ่านpathโดยตรง
        if (res.data.video.status == process.env.REACT_APP_READY_TO_VIEW) {
          setTileVideo(res.data.video.title);
          setDataTask(res.data);
          getScenario_model(res.data.scenario_id[0].scenario_id);
          setIsLoading(false);
        } else {
          navigate("/404");
        }
        // console.log("res getData:", res);
        // setTileVideo(res.data.video.title);
        // setDataTask(res.data);
        // getScenario_model(res.data.scenario_id[0].scenario_id);
      } else {
        navigate("/404");
        openNotification("error", "Cannot get video", "");
      }
      // const interval = setInterval(() => {
      //   console.log("This will run every second!");
      //   setIsLoading(false);
      //   // }, 3000);
      // }, 1500);
      // return () => clearInterval(interval);
    } catch (e) {
      openNotification("error", "Cannot get video", "");
      console.error(e);
    }
  };

  const getScenario_model = async (id: any) => {
    try {
      const res = await get_ScenarioByID({ id: id });

      const result = res.result.model.map((d: any) => {
        return { ...d, color: "pink" };
      });
      const dataModelText = result.filter(
        (i: any) => i.model_type == "b875162b-0492-11ee-a8f8-0242ac1b0005"
      );
      const dataModelObject = result.filter(
        (i: any) => i.model_type == "b875130b-0492-11ee-a8f8-0242ac1b0005"
      );
      const dataModelScene = result.filter(
        (i: any) => i.model_type == "b8750eec-0492-11ee-a8f8-0242ac1b0005"
      );
      if (dataModelText.length != 0) {
        setRadioScenario(1);
      } else if (dataModelObject.length != 0) {
        setRadioScenario(2);
      } else if (dataModelScene.length != 0) {
        setRadioScenario(3);
      }
      setRadioModel({
        text: dataModelText,
        object: dataModelObject,
        scene: dataModelScene,
      });
      setDataModel(result);
    } catch (e) {
      console.error(e);
    }
  };

  const getSubtitle = async () => {
    try {
      const res = await subtitle_APIDownload({ task_id: params.id });
      console.log("res subtitle::", res);
      const sub_path = window.webkitURL.createObjectURL(res);
      setSubtitle(sub_path);
    } catch (e) {
      console.error(e);
    }
  };

  const getDataResultModel = async () => {
    try {
      const res: any = await mongo_getData(params.id);

      ///---///
      let alldata: any = [];
      res.object_task.map((i: any) => {
        i.annotation.map((d: any) => {
          alldata.push(d);
        });
      });

      const test = alldata.reduce((group: any, product: any) => {
        const { object } = product;
        // console.log("product", product);
        group[object] = group[object] ?? [];
        group[object].push(product);
        return group;
      }, {});

      setDataScenario([res]);
    } catch (e) {
      console.error(e);
    }
  };
  const [loadingCanvas, setLoadingCanvas] = useState(false);

  const checkDrawImage = async () => {
    try {
      //
      const res: any = await task_getDashboardID({ task_id: params.id });
      //
      // const res: any = await mongo_getData(params.id);
      // console.log("res checkDrawImage", res.object_task[0].screenshot);
      // const check_path: any = res?.object_task[0]?.screenshot?.split("/");
      // console.log("check_path", check_path);
      //server
      // if (check_path[0] != "memo") {
      //local
      // if (check_path[0] != "uploads") {

      if (res.status == "No Data") {
        setLoadingCanvas(true);
        // console.log("in send draw");
        // const draw_res = await task_drawCanvas(res);
        // console.log("draw_res::", draw_res);
        // if (draw_res.status == "success") {
        setLoadingCanvas(false);
        getData();
        getDataSubTitle();
        getSubtitle();
        getDataResultModel();
        createDashboard();
        // }
      } else {
        getData();
        getDataSubTitle();
        getSubtitle();
        getDataResultModel();
        //test
        // createDashboard()
      }
    } catch (e) {
      console.error(e);
    }
  };
  let firstLoading = true;

  const createDashboard = async () => {
    try {
      const dataresult = {
        task_id: params.id,
      };
      const res = await api_getVideoID(dataresult);
      const data: any = await mongo_getData(params.id);
      const dataScenarioModel = await get_ScenarioByID({
        id: res.data.scenario_id[0].scenario_id,
      });
      const allModel = await selectedAllModel();
      //new json image
      const countObject = await task_getCountModels({
        task_id: params.id,
        type: "object_task",
      });
      const conutScene = await task_getCountModels({
        task_id: params.id,
        type: "scene_task",
      });
      const obj_area = await task_getDataModels({
        task_id: params.id,
        model: "ad_area",
      });
      const dataCheckRatio = obj_area[0]?.data.filter((item: any) => {
        if (item.ratio < 0.125) {
          return item;
        }
      }).length;
      const newCount = {
        ...countObject.counts,
        ad_area:
          countObject.counts["ad_area"] == undefined
            ? 0
            : countObject.counts["ad_area"] - dataCheckRatio,
        ...conutScene.counts,
      };

      const resultModel = dataScenarioModel.result.model.map((d: any) => {
        return { ...d, color: "pink" };
      });

      ////////////////
      //check data model tag scene
      const dataModelScene = resultModel.filter(
        (i: any) => i.model_type == "b8750eec-0492-11ee-a8f8-0242ac1b0005"
      );

      // let alldatascene: any = [];
      // data.scene_task.map((i: any) => {
      //   // i.annotation.map((d: any) => {
      //   alldatascene.push(i);
      //   // });
      // });

      const resultTagScene = dataModelScene.map((d: any) => {
        if (newCount[d.model_name] != undefined) {
          return {
            name: d.text,
            value: newCount[d.model_name], //จำนวนจุด
            // value:1 //จำนวนไฟล์
          };
        } else {
          return null;
        }
      });

      //check data model tag object
      const dataModelObject = resultModel.filter(
        (i: any) => i.model_type == "b875130b-0492-11ee-a8f8-0242ac1b0005"
      );

      // let alldataobj:any = [];
      // data.object_task.map((i:any) => {
      //   i.annotation.map((d:any) => {
      //     alldataobj.push(d);
      //   });
      // });

      const resultTagObj = dataModelObject
        .map((d: any) => {
          if (newCount[d.model_name] != undefined) {
            return {
              name: d.text,
              value: newCount[d.model_name], //จำนวนจุด
              // value:1 //จำนวนไฟล์
            };
          } else {
            return null;
          }
        })
        .filter((item: any) => item != null);

      //check data model tag text
      const dataModelText = resultModel.filter(
        (i: any) => i.model_type == "b875162b-0492-11ee-a8f8-0242ac1b0005"
      );

      let alldatatext: any = [];
      data.text_subtitle.map((i: any) => {
        i.model_name.map((d: any) => {
          alldatatext.push(d);
        });
      });

      const tagText = alldatatext.reduce((group: any, product: any) => {
        console.log(" product.highlight.length", product);
        const { name } = product;
        group[name] = group[name] ?? [];
        group[name].push({ value: product.highlight.length });
        return group;
      }, {});

      const calculateSum = (array: any, property: any) => {
        console.log("array", array);
        console.log("property", property);
        const total = array.reduce((accumulator: any, object: any) => {
          return accumulator + object[property];
        }, 0);

        return total;
      };

      const resultTagText = dataModelText
        .map((d: any) => {
          if (tagText[d.model_name] != undefined) {
            console.log("tag model name::", tagText[d.model_name]);
            return {
              name: d.text,
              value: calculateSum(tagText[d.model_name], "value"),
            };
          } else {
            return null;
          }
        })
        .filter((item: any) => item != null);

      const all_result = [...resultTagText, ...resultTagObj, ...resultTagScene];

      const checkDetect = all_result
        .filter((item: any) => item != null)
        .filter((item: any) => item.value != 0);

      const result_dashboard = {
        task_id: res.data.video.task_id,
        org: res.data.video.org,
        detect: checkDetect.length == 0 ? 0 : 1,
        user: res.data.video.user_id,
        data: JSON.stringify(all_result.filter((item: any) => item != null)),
      };

      console.log("result_dashboard", result_dashboard);
      const res_dash = await task_createDashboard(result_dashboard);
    } catch (e) {
      console.error(e);
    }
  };

  const [allSub, setAllSub] = useState([]);

  const [indexSelect, setIndexSelect] = useState("");
  const getDataSubTitle = async () => {
    try {
      const res = await subtitle_APIGetSub({ task_id: params.id });
      setAllSub(res.result);
      setIndexSelect(res.result[0].sub_id);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (firstLoading) {
      firstLoading = false;
      console.log("in useEffect");
      checkDrawImage();
    }
  }, []);

  const header = () => {
    return (
      <Row align="middle">
        <Breadcrumb
          separator=" "
          items={[
            {
              title: (
                <h3>
                  <LeftCircleFilled
                    className="brebadcrumb-back"
                    onClick={() => navigate(-1)}
                  />
                </h3>
              ),
            },
            {
              title: <h3 className="brebadcrumb-title">{titleVideo}</h3>,
            },
          ]}
        />
      </Row>
    );
  };

  return (
    <div className="content-container">
      <Spin spinning={loadingCanvas} tip={"Processing..."} size="large">
        <Row>
          <h1 className="header-topic topic-color">Video</h1>
        </Row>

        <Spin spinning={isLoading}>
          <Row className="mt-05" gutter={[32, 16]}>
            <VideoDisplay
              header={header}
              subtitle={subtile}
              seekTime={seekTime}
              endTime={endTime}
              allSub={allSub}
              indexSelect={indexSelect}
              focusRow={focusRow}
              setFocusRow={setFocusRow}
            />
            <DetailDisplay
              dataTask={dataTask}
              radioScenario={radioScenario}
              setRadioScenario={setRadioScenario}
              getScenario_model={getScenario_model}
              dataModel={dataModel}
              radioModel={radioModel}
              dataScenario={dataScenario}
              setSeekTime={setSeekTime}
              setEndTime={setEndTime}
              focusRow={focusRow}
              setFocusRow={setFocusRow}
            />
          </Row>
        </Spin>
      </Spin>
    </div>
  );
};

export default VideoDetail;

import { Button, Col, Row, Space, Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useMemo, useRef, useState } from "react";
import { Highlight } from "./Highlight";
import scrollIntoView from 'scroll-into-view';
// import { Button } from "../../../../assets/html/memo/assets/vendor/bootstrap/js/bootstrap.bundle";

// type Props = {
//   dataModel: any;
//   dataScenario: any;
//   selectModelText: any;
//   setSelectModelText: any;
//   selectTags: any;
//   setSelectTags: any;
// };

const Text = ({
  dataModel,
  dataScenario,
  selectModelText,
  setSelectModelText,
  selectTags,
  setSelectTags,
  focusRow,
  setFocusRow
}) => {
  const columns = [
    {
      title: "Start Time",
      dataIndex: "start_time",
      key: "start_time",
      align: "center",
      width: "20%",
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      key: "end_time",
      align: "center",
      width: "20%",
    },
    {
      title: <Row justify="end"></Row>,
      dataIndex: "text",
      key: "text",
      render: (_, row) => {
        let textHighlight = [];
        const resultTxt = row.model_name
          .filter((item) => {
            if (selectTags == "") {
              if (item.highlight.length != 0) {
                const res = item.highlight.map((d) => {
                  return d;
                });
                return res;
              }
            } else if (item.name == selectTags) {
              if (item.highlight.length != 0) {
                const res = item.highlight.map((d) => {
                  return d;
                });
                return res;
              }
            }
          })
          .map((d) => {
            const res = d.highlight.map((item) => {
              console.log("item tag:", d)
              dataModelText.map((item_text) => {
                if (item_text.model_name == d.name) {
                  textHighlight.push(item);
                  return item;
                } else {
                  return item;
                }
              })
              // textHighlight.push(item);
              // return item;
            });
            return res;
          });
        return (
          <Highlight
            tags={textHighlight.sort(
              (a, b) => a.being_char - b.being_char
            )}
          >
            {row.text}
          </Highlight>
        );
      },
    },
  ];
  const data_env = process.env.REACT_APP_MODEL_COLOR
  const model_color = JSON.parse(data_env)
  const [arrText, setArrText] = useState([])
  const ref = useRef(null);
  let myVideo = document.getElementById("video");


  const SecToTime = (timeInSeconds) => {
    console.log("timeInSeconds", timeInSeconds)
    var date = new Date(0);
    date.setSeconds(timeInSeconds); // specify value for SECONDS here
    var timeString = date.toISOString().substring(11, 19);
    return timeString
    // let time = parseFloat(timeInSeconds).toFixed(3);
    // let hours = Math.floor(time / 60 / 60);
    // let minutes = Math.floor(time / 60) % 60;
    // let seconds = Math.floor(time - minutes * 60);
    // let milliseconds = time.slice(-3);
    // return new Date(timeInSeconds)
    // .toISOString()
    // .slice(11, 19)
    // return (
    //   pad(hours, 2) +
    //   ":" +
    //   pad(minutes, 2) +
    //   ":" +
    //   pad(seconds, 2) +
    //   "." +
    //   pad(milliseconds, 3)
    // );
  };

  const pad = (num, size) => {
    return ("000" + num).slice(size * -1);
  };

  // const res: any = dataScenario[0]?.text_subtitle.map((d: any, ind: number) => {
  //   return {
  //     id: d.sub_id,
  //     time: SecToTime(Number(d.start_time)),
  //     start_time: SecToTime(Number(d.start_time)),
  //     end_time: SecToTime(Number(d.end_time)),
  //     model_name: d.model_name,
  //     text: d.text,
  //   };
  // });

  const dataModelText = dataModel.filter(
    (i) => i.model_type == "b875162b-0492-11ee-a8f8-0242ac1b0005"
  );
  let alldata = [];
  dataScenario[0]?.text_subtitle.map((i) => {
    i.model_name.map((d) => {
      alldata.push(d);
    });
  });

  const tagObject = alldata.reduce((group, product) => {
    // console.log(" product.highlight.length", product)
    const { name } = product;
    group[name] = group[name] ?? [];
    group[name].push({ value: product.highlight.length });
    return group;
  }, {});
  // console.log("tagObject text",tagObject)

  // let datatoarr = [];
  // for (let key in tagObject) {
  //   datatoarr.push({name:key,value:tagObject[key]});
  // }
  const calculateSum = (array, property) => {
    console.log("array", array)
    console.log("property", property)
    if (array != undefined) {
      const total = array.reduce((accumulator, object) => {
        return accumulator + object[property];
      }, 0);

      return total;
    }

  };

  const resultTag = dataModelText.map((d) => {
    if (tagObject[d.model_name] != undefined) {
      return { name: d.text, value: calculateSum(tagObject[d.model_name], "value") }
    } else {
      return null
    }
  }).filter((item) => item != null)

  dataModelText?.map((d) => {
    const lengthText = calculateSum(tagObject[d.model_name], "value")

    if (lengthText == 0) {

    } else if (lengthText > 0 && selectModelText.length == 0) {
      setSelectModelText(d.model_name)
    }
  })

  useEffect(() => {
  }, [dataScenario])

  const dataModelSet = useMemo(() => {
    let test = []
    console.log("tagObject", tagObject)
    const testmodel = dataModel && dataModel.filter(
      (i) => i.model_type == "b875162b-0492-11ee-a8f8-0242ac1b0005"
    ).map((d) => {

      const lengthText = calculateSum(tagObject[d.model_name], "value")
      console.log("lengthText::::", lengthText)

      if (lengthText == 0) {

      } else if (lengthText > 0 && selectModelText.length == 0) {

        // return d.model_name
        test = d.model_name
      }
    })
    setSelectModelText(test)
    console.log("selectModelText::::testmodel", testmodel)
    return test
  }, [dataModel])

  const testResult = useMemo(() => {
    console.log("tagObject::::tagObject", tagObject)

    const res = dataScenario[0]?.text_subtitle.map((d, ind) => {
      return {
        id: d.sub_id,
        time: SecToTime(Number(d.start_time)),
        start_time: SecToTime(Number(d.start_time)),
        end_time: SecToTime(Number(d.end_time)),
        model_name: d.model_name,
        text: d.text,
      };
    });
    //
    ///
    const data = res && res.map((item) => {
      console.log("item test result", item)
      console.log("dataModelSet test result", dataModelSet)
      const result = item.model_name.map((d) => {
        if (d.name == dataModelSet && d.highlight.length != 0) {
          return { ...d, id: item.id }
        }
      }).filter((d) => d != undefined)
      console.log("result filter", result)
      return result[0]
    }).filter((d) => d != undefined)
    console.log("data filter", data)

    setArrText(data)
    ///
    console.table("res test result", res)

    return res
  }, [dataScenario, dataModel])

  const [textHighlightSelect, setTextHighlightSelect] = useState(null)
  const backScroll = () => {
    try {
      if (textHighlightSelect == null) {
        setTextHighlightSelect(0)

        scrollIntoView(document.querySelector(`.scroll-${arrText[0].id}`), {
          align: {
            top: 0,
          },
        });
      } else {
        if (textHighlightSelect == 0) {
          setTextHighlightSelect(0)
          scrollIntoView(document.querySelector(`.scroll-${arrText[0].id}`), {
            align: {
              top: 0,
            },
          });
        } else {
          setTextHighlightSelect(textHighlightSelect - 1)
          scrollIntoView(document.querySelector(`.scroll-${arrText[textHighlightSelect - 1].id}`), {
            align: {
              top: 0,
            },
          });
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  const nextScroll = () => {
    try {
      if (textHighlightSelect == null) {
        setTextHighlightSelect(0)
        scrollIntoView(document.querySelector(`.scroll-${arrText[0].id}`), {
          align: {
            top: 0,
          },
        });
      } else {
        if (textHighlightSelect + 1 == arrText.length) {
          setTextHighlightSelect(textHighlightSelect)
          scrollIntoView(document.querySelector(`.scroll-${arrText[textHighlightSelect].id}`), {
            align: {
              top: 0,
            },
          });
        } else {
          setTextHighlightSelect(textHighlightSelect + 1)
          scrollIntoView(document.querySelector(`.scroll-${arrText[textHighlightSelect + 1].id}`), {
            align: {
              top: 0,
            },
          });
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Row>
      <Col xs={20}>
        <Space size={[0, 8]} wrap>
          {dataModelText.map((d) => {
            console.log("d", d);
            return (
              <Tag
                key={d.model_id}
                // color={dataModelSet != d.model_name ? "default" : "#55acee"}
                color={selectModelText != d.model_name ? "default" : "#55acee"}
                // color={model_color[d.model_name]}
                bordered={false}
                // bordered={selectModelText == d.model_name ?true:false}
                // className={dataModelSet == undefined ?"video-tag-selected":dataModelSet != d.model_name ? "video-tag-unselected" : "video-tag-selected"}
                className={selectModelText.length == 0 ? "video-tag-selected" : selectModelText != d.model_name ? "video-tag-unselected" : "video-tag-selected"}
                style={{ cursor: "pointer", borderWidth: "4px" }}
                onClick={() => {
                  setSelectModelText(d.model_name);
                  setSelectTags(d.model_name);
                }}
              >
                {d.text} (
                {tagObject[d.model_name] == undefined
                  ? 0
                  : calculateSum(tagObject[d.model_name], "value")}
                )
              </Tag>
            );
          })}
        </Space>
      </Col>
      <Col sm={4}>
        <Button disabled={textHighlightSelect == null || textHighlightSelect == 0} onClick={backScroll}>{"<"}</Button>
        <Button disabled={textHighlightSelect + 1 == arrText?.length} onClick={nextScroll}>{">"}</Button>
      </Col>
      <Col xs={24}>
        <Row>
          {Array(5).map((d) => {
            return <Col xs={6}>test</Col>;
          })}
        </Row>
      </Col>
      <Col xs={24} style={{ marginTop: "10px" }}>
        <Table
          // ref={ref}
          rowClassName={(record, index) => (`scroll-${record.id}`)}
          rowKey="id"
          columns={columns}
          // dataSource={res}
          dataSource={testResult}
          onRow={(record, index) => {
            return ({
              style: {
                background: `.scroll-${record.id}` == focusRow ?
                  // '#FAFAFA' 
                  '#c7c5c5'
                  : 'white',
              },
              onClick: event => {
                let a = record.start_time.split(':')
                let seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
                myVideo.currentTime = seconds;
              },
            })
          }}
          pagination={false}
          scroll={{ y: 300 }}
        />
      </Col>
    </Row>
  );
};

export default Text;

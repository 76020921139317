import config from "../config/config";
import { getToken } from "../utils/prefix";

  export async function getHistory_apiAdmin(data) {
    const res = await fetch(process.env.REACT_APP_BACKEND+ `/history/getall`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        'x-access-token':
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiODA1MTM2Iiwicm9sZSI6MSwiaWF0IjoxNjg3NDQ1ODUxLCJleHAiOjE3MDQwMzQ2NTF9.gXMcRV-biF8gkSRjQKgUzLsriloT9tT75Cy831HCJ_E',
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }

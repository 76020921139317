import config from "../config/config";
/* The line `import { getToken } from "../utils/prefix";` is importing the `getToken` function from the
`../utils/prefix` module. This function is likely used to retrieve an authorization token for making
authenticated requests to the API. */
import { getToken } from "../utils/prefix";

export async function user_login(data) {
  const res = await fetch(process.env.REACT_APP_BACKEND+ `/user/login`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function user_register(data) {
  const res = await fetch(process.env.REACT_APP_BACKEND+ `/user/register`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },
  });
  
  const dataRespone = await res.json();
  return dataRespone;
}

export async function get_profile(){
  const res = await fetch(process.env.REACT_APP_BACKEND+ `/user/profile`, {
    body: JSON.stringify(),
    method: "POST",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },
    
  })
  const dataRespone = await res.json();
  return dataRespone;
}
export async function edit_profile(data){
  const res = await fetch(process.env.REACT_APP_BACKEND+ `/user/edit`, {
    body: JSON.stringify(data),
    method: "PUT",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },

  })
  const dataRespone = await res.json();
  return dataRespone;
}

export async function upload_profilepic(formData){
  const res = await fetch(process.env.REACT_APP_BACKEND+ `/user/uploadprofile`, {
    body: formData,
    method: "POST",
    headers: {
      "x-access-token": getToken(),
    },

  })
  const dataRespone = await res.json();
  return dataRespone;
}

export async function check_UserStatus(){
  const res = await fetch(process.env.REACT_APP_BACKEND+ `/user/checkstatus`, {
    // body: JSON.stringify(data),
    method: "GET",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },

  })
  const dataRespone = await res.json();
  return dataRespone;
}



// export async function test_get() {
//     const res = await fetch(process.env.REACT_APP_BACKEND+ `/topic/gettopic`, {
//       method: "GET",
//       headers: {
//         Authorization: getToken(),
//         "Content-type": "application/json",
//       },
//     });
  
//     const dataRespone = await res.json();
//     return dataRespone;
//   }


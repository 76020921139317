import { atom, selector } from "recoil";
import { recoilPersist } from "recoil-persist";
const { persistAtom } = recoilPersist();


export const localeState = atom({
  key: "localeState",
  default: {},
});

export const searchState = atom({
  key: "searchState",
  default: [],
});

export const valueSearch = atom({
  key: "valueSearch",
  default: '',
});

import { Pagination, Row, Select, Space } from "antd";
import { t } from "i18next";
import React from "react";

type Props = {
  total: any;
  pageSize: any;
  setCurrentPage: any;
  onSelect: (data: any) => void;
};

const PaginationComponent = ({
  total,
  pageSize,
  setCurrentPage,
  onSelect,
}: Props) => {
  return (
    <>
      <Row
        align="bottom"
        justify="space-between"
        style={{ margin: "10px 16px 0px 16px" }}
      >
        <h1>
          {t("Total")} {total} {total > 1 ? t("Videos") : t("Video")}
        </h1>
        <Space
          direction="horizontal"
          align="center"
          style={{ justifyContent: "center" }}
          wrap
        >
          <Pagination
            className="video-pagination"
            total={total}
            showSizeChanger={false}
            pageSize={pageSize}
            onChange={(page) => setCurrentPage(page)}
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ margin: 0, fontWeight: 500 }}>
              {t("Cards per page")} :
            </p>
            <Select
              onSelect={onSelect}
              bordered={false}
              style={{ width: "80px" }}
              value={pageSize}
              defaultValue={8}
              options={[
                { value: 8, label: 8 },
                { value: 16, label: 16 },
                { value: 24, label: 24 },
                { value: 32, label: 32 },
              ]}
            ></Select>
          </div>
        </Space>
      </Row>
    </>
  );
};

export default PaginationComponent;
